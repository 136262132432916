import './Home.scss';

import {useLocation} from "react-router-dom";
import {lazy, Suspense, useEffect, useState} from 'react';
import {setEventScroll} from '../../services/AnalyticsServices';

import HomeHeader from "../../components/new-home/header/HomeHeader";
import HomeMainGeneral from "../home/home-main-general/HomeMainGeneral";

const HomeHowTo = lazy(() => import('../new-home/how-to/HomeHowTo'));
const HomeAboutUthere = lazy(() => import('../new-home/about-uthere/HomeAboutUthere'));
const HomePartners = lazy(() => import('../new-home/partners/HomePartners'));
const HomeReviews = lazy(() => import('../new-home/reviews/HomeReviews'));
const HomeFaqs = lazy(() => import('../new-home/faqs/HomeFaqs'));

/*
* HomeHeader
*/
function Home() {
    const location = useLocation();
    const [percentagesRecorded, setPercentagesRecorded] = useState({
        10: false,
        20: false,
        30: false,
        40: false,
        50: false,
        60: false,
        70: false,
        80: false,
        90: false,
        100: false,
    });

    useEffect(() => {
        document.body.addEventListener("scroll", setScrollEvents, true);

        return () => {
            document.body.removeEventListener('scroll', setScrollEvents, true);
        }
    }, []);

    useEffect(() => {
        const prefetchComponents = async () => {
            await HomeReviews;
            await HomeHowTo;
            await HomeAboutUthere;
            await HomePartners;
            await HomeFaqs;
        };
        prefetchComponents();
    }, []);

    const setScrollEvents = () => {
        setPercentagesRecorded(previousState => {
            let percent = (document.body.scrollTop / document.body.scrollHeight) * 100;
            let nearest = Math.round(percent / 10) * 10;
            if (nearest && !previousState[nearest]) {
                previousState[nearest] = true;
                try {
                    setEventScroll(location.pathname, nearest);
                } catch (e) {
                }
            }
            return {
                ...previousState,
            };
        })
    }

    return (
        <div className='home-container'>
            <HomeHeader simular={true} branch={0}/>
            <HomeMainGeneral/>
            <Suspense fallback={null}>
                <HomeReviews branch={0}/>
                <HomeHowTo branch={0}/>
                <HomeAboutUthere branch={0}/>
                <HomePartners branch={0}/>
                <HomeFaqs main={true} branch={1}/>
            </Suspense>
        </div>
    );
}

export default Home;

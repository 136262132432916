import {useLocation, useNavigate} from "react-router-dom";
import useNotifications from '../../../hooks/useNotifications';
import HomeNotifications from '../../home/home-notifications/HomeNotifications';
import autoImage from '../../../assets/images/home/auto-banner-s.webp';
import homeImage from '../../../assets/images/home/home-icon.webp';
import lifeImage from '../../../assets/images/home/life-banner.webp';
import healthImage from '../../../assets/images/home/saude-icon.webp';

/**
 * HomeHeader
 */

const secure = [
    {
        "name": "Automóvel",
        "image": autoImage,
        "branch": 4,
        "slug": "auto"
    },
    {
        "name": "Multirriscos Habitação",
        "image": homeImage,
        "branch": 3,
        "slug": "casa"
    },
    {
        "name": "Vida",
        "image": lifeImage,
        "branch": 1,
        "slug": "vida"
    },
    {
        "name": "Saúde",
        "image": healthImage,
        "branch": null
    },
];


function HomeMainGeneral(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const notifications = useNotifications();

    const goToSimulation = (branch, slug) => {
        if(props.branch == null || props.branch === branch){
            import("../../../services/AnalyticsServices").then(async analyticsServicsImpl => {
                analyticsServicsImpl.setEventCTASelected(
                    "Simular agora",
                    'CTA Principal', 1, branch, 0, location.pathname
                )
            })
            navigate(`/simulacao?branch=${branch}`);
        }else{
            navigate(`/${slug}`);
        }
    }

    return (
        <div className={`w-full relative flex flex-col justify-center items-center ${props.branch == null ? 'bg-primary-50 py-14 xs:py-16 md:py-18 lg:py-20 xl:py-22 2xl:py-28' : 'pt-8 pb-14 xs:pt-10 xs:pb-16 md:pt-12 md:pb-18 lg:pb-20 xl:pt-14 xl:pb-22 2xl:pt-18 2xl:pb-28'} border-b border-primary-200`}>
            {notifications && notifications.length > 0 ?
                <div className='flex justify-end sm:w-80 sm:right-5 sm:top-18 sm:fixed sm:z-99 md:right-5.5 md:top-20
            lg:w-96 lg:right-9 lg:top-22.5 xl:w-140 xl:right-7.5 xl:top-30'>
                    <HomeNotifications hideMore={false}/>
                </div> :
                null}
            {props.branch == null ?
                <div className='container flex flex-col justify-center items-center gap-6 xs:gap-8 sm:flex-col sm:z-1 md:gap-12 lg:gap-14 xl:gap-16 text-center'>
                    <div className="lg:w-2/3 px-1 xs:px-2 sm:px-6 md:px-8 xl:px-12">
                        <h1 className='text-25 xs:text-27 md:text-38 xl:text-47'><span className='font-bold'>Ajudamos a <span
                            className="whitespace-nowrap">escolher </span></span>o melhor Seguro para si</h1>
                    </div>
                    <div className="lg:w-2/3 px-1 xs:px-2 sm:px-6 md:px-8 xl:px-12">
                        <h2 className='text-lg xs:text-19 md:text-23 font-light'>Simule e conheça a nossa recomendação <span className='whitespace-nowrap'>em segundos</span></h2>
                    </div>
                </div> :
                <div className='container flex flex-col justify-center items-center gap-6 sm:flex-col sm:z-1
            md:gap-12 lg:gap-10 text-center'>
                    <div>
                        <h2 className='text-22 xs:text-2xl md:text-32 xl:text-38'><span className='font-bold'>Conheça os seguros</span> que temos para si</h2>
                    </div>
                </div>
            }

            <div
                className="container grid grid-cols-2 gap-3 mt-12 xs:mt-14 md:mt-18 lg:mt-20 xl:mt-22 xs:gap-4 md:gap-6 lg:gap-8 xl:gap-10 lg:grid-cols-4">
                {
                    secure.map((item, index) => (
                        <button onClick={() => item.branch ? goToSimulation(item.branch, item.slug) : null} draggable="false"
                                key={index}
                                className={`bg-white flex flex-col items-center rounded-xl xs:rounded-2xl px-2 pt-4 pb-2 justify-between shadow-lg transition-all duration-500 xs:px-3 xs:pt-6 xs:pb-3 md:px-4 md:pt-8 md:pb-4 lg:px-3 lg:pt-4 lg:pb-3 xl:px-4 xl:pt-6 xl:pb-4 2xl:px-6 2xl:pt-8 2xl:pb-6 ${props.branch != null ? 'border border-primary-100' : ''} ${item.branch ? 'hover:shadow-2xl' : 'cursor-default'}`}>
                            <figure className="">
                                <img className="w-16 h-16 xs:w-18 xs:h-18 xl:w-22 xl:h-22" src={item.image} width="80"
                                     height="80" alt={item.name} title={item.name} draggable="false" loading="eager"/>
                            </figure>
                            <p className="text-center my-2 mb-4 text-sm text-neutral-900 md:mt-6 md:mb-8 xl:mt-6 xl:mb-10 lg:text-base sm:w-1/2">{item.name}</p>
                            {item.branch === props.branch || props.branch == null ? <span
                                    className={`py-3.875 xs:py-4.375 sm:py-3.625 md:py-4.25 lg:py-5.5 rounded-lg xs:rounded-xl sm:rounded-lg md:rounded-xl lg:rounded-2xl transition-all duration-300 w-full text-center font-medium ${item.branch ? 'bg-primary-500 text-white border border-primary-500 text-14 xs:text-15 md:text-base xl:text-17 2xl:text-lg' : 'bg-gradient-to-b from-white to-primary-300 text-neutral-800 cursor-default text-11 xs:text-13 md:text-14 xl:text-16_24'}`}>{item.branch ? 'Simular' : 'Brevemente'}</span> :
                                <span
                                    className={`py-3.875 xs:py-4.375 sm:py-3.625 md:py-4.25 lg:py-5.5 rounded-lg xs:rounded-xl sm:rounded-lg md:rounded-xl lg:rounded-2xl transition-all duration-300 w-full text-center font-medium ${item.branch ? 'border border-primary-500 text-primary-500 text-14 xs:text-15 md:text-base xl:text-17 2xl:text-lg' : 'bg-gradient-to-b from-white to-primary-300 text-neutral-800 cursor-default text-11 xs:text-13 md:text-14 xl:text-15 2xl:text-16_24'}`}>{item.branch ? 'Ver seguro' : 'Brevemente'}</span>
                            }
                        </button>
                    ))
                }
            </div>
        </div>
    );
}

export default HomeMainGeneral;

import './../../css/Common.scss'
import '../../index.scss';
import '../../App.scss';
import './Home.scss';

import {lazy, useEffect} from 'react';
import { Helmet } from 'react-helmet';
import {initPage} from '../../logic/pages/PagesLogic';
import WebFont from 'webfontloader';
import HomeComponent from '../../components/home/Home';
import shareImage from '../../../src/assets/share/uthere-share-geral.webp';

const UthereScrollTop = lazy(() => import('../../components/common/uthere-scroll-top/UthereScrollTop'));
const UthereFooter = lazy(() => import('../../components/footer/Footer'));


/**
 * Home Uthere
 */
function Home() {

    useEffect(() => {
        WebFont.load({
            custom: {
                families: ['Montserrat'],
            },
        });
        import("../../services/AnalyticsServices").then(async analyticsImpl => {
            analyticsImpl.setLoadedApp("home");
        });

        initPage('home');
    }, []);

    const canonicalUrl = window.location.origin + window.location.pathname;

    return (
        <div>
            <Helmet>
                <title>uthere - Ajudamos a escolher o melhor seguro para si</title>
                <meta name="description"
                      content="Seguro de Carro, Casa e Vida. Procuramos no mercado a melhor opção para si. Óptimos preços. Simule e compre em minutos."/>
                <link rel="canonical" href={canonicalUrl}/>
                <meta property="og:title" content="Ajudamos a escolher o melhor seguro para si. Simule e compre em minutos."/>
                <meta property="og:description"
                      content="Seguros de Carro, Casa e Vida"/>
                <meta property="og:image" content={window.location.origin + shareImage}/>
                <meta property="og:url" content={canonicalUrl}/>
                <meta property="og:type" content="website"/>
                <meta name="twitter:title" content="Ajudamos a escolher o melhor seguro para si. Simule e compre em minutos."/>
                <meta name="twitter:description"
                      content="Seguros de Carro, Casa e Vida"/>
                <meta name="twitter:card" content={window.location.origin + shareImage}/>
                <meta name="twitter:image" content={window.location.origin + shareImage}/>
            </Helmet>
            <HomeComponent/>
                <UthereFooter/>
                <UthereScrollTop/>
        </div>
    )
};
export default Home;
